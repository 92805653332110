import { Outlet } from 'react-router-dom';

function ProjectsPage() {
  return (
    <>
      <Outlet/>
    </>
  )
}

export default ProjectsPage;